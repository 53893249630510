<template>
  <div class="user-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="user-page__header" v-if="!loading">
      <div class="user-page__name">
        <v-avatar
            color="#FFFFFF"
            size="115"
            class="user-page__avatar mr-5"
        >
          <img
              :src="user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
              alt=""
          >
        </v-avatar>
        <v-menu
            top
            offset-y
            open-on-hover
            max-height="156"
        >
          <template v-slot:activator="{ on, attrs }">
                <span class="users__status" v-bind="attrs" v-on="on">
                    <img v-if="user.is_online" :src="require('@/assets/img/users/is_active.svg')"/>
                    <img v-else :src="require('@/assets/img/users/is_fired.svg')"/>
                </span>
          </template>
          <v-list-item class="user-color-status d-flex justify-space-between align-center" v-if="user.is_online">
            <img class="mr-4" :src="require('@/assets/img/users/green.svg')"/>
            <span class="user-color-status--green">В сети</span>
          </v-list-item>
          <v-list-item class="user-color-status d-flex justify-space-between align-center" v-else>
            <img class="mr-4" :src="require('@/assets/img/users/red.svg')"/>
            <span class="user-color-status--red">Не в сети</span>
          </v-list-item>
        </v-menu>
        <div>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="userName"
              disabled
          >
            <template v-slot:label>
              Имя
            </template>
          </v-text-field>
          <div class="user-page__actions ml-2 mt-1">
            <v-btn text class="evi-button-text" @click="chat(user.id)" v-if="profile.id !== user.id">
              <img class="mr-2" :src="require('@/assets/img/menu/chat.svg')" alt="">
              Перейти в чат
            </v-btn>
            <v-btn text class="evi-button-text" @click="openUserAnalytics(user.id)" v-if="canViewAnalytics">
              <img class="mr-2" :src="require('@/assets/img/menu/analytics.svg')" alt="">
              Посмотреть аналитику
            </v-btn>
            <v-btn text class="evi-button-text" v-if="user.is_active && !user.is_main && hasRight('users.manage')"
                   @click="openRemoveUserModal">
              <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
              Уволить
            </v-btn>
            <v-btn text class="evi-button-text" v-else-if="!user.is_active && hasRight('users.manage')"
                   @click="restoreUserBack">
              <img class="mr-2" :src="require('@/assets/img/users/green.svg')" alt="">
              Восстановить
            </v-btn>
          </div>
        </div>
      </div>
      <div class="user-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="user-page__body" v-if="!loading">
      <div class="user-page__positions">
        <v-text-field
            v-model="user.id"
            label="ID"
            class="evi-text-field mb-7"
            color="#44D370"
            readonly
            hide-details
        />
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            v-model="role"
            :items="localRoles"
            item-text="name"
            item-value="id"
            @change="editUserPage"
            :disabled="!hasRight('users.manage') || user.is_main"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Роль
          </template>
          <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="user-page__roles-select">
              <span class="color" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="user-page__roles-select">
              <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            v-model="subdivision"
            :items="localSubdivisions"
            item-text="name"
            item-value="id"
            @change="editUserPage"
            :disabled="!hasRight('users.manage')"
            :loading="subdivisionLoading"
            @focus="loadSubdivisions"
            :search-input.sync="subdivisionSearch"
            @input.native="searchSubdivision(subdivisionSearch)"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Подразделение
          </template>
          <template v-slot:append-item>
            <div v-intersect="subdivisionIntersect"/>
          </template>
          <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
        </v-autocomplete>
        <v-text-field
            v-model="position"
            label="Должность"
            class="evi-text-field mb-7"
            color="#44D370"
            @change="editUserPage"
            :disabled="!hasRight('users.manage')"
            hide-details
        />
        <v-text-field
            v-model="email"
            label="Email"
            class="evi-text-field mb-7"
            color="#44D370"
            readonly
            hide-details
        />
        <v-text-field
            v-model="activityTime"
            label="Активность сегодня"
            class="evi-text-field mb-7"
            color="#44D370"
            readonly
            hide-details
        ></v-text-field>
        <v-text-field
            class="evi-text-field mb-4"
            color="#44D370"
            v-model="plan"
            v-mask="currencyMask"
            @change="editUserPage"
            v-if="canChangeUserPlan"
            hide-details
        >
          <template v-slot:label>
            План на месяц
          </template>
        </v-text-field>
        <div class="current-plan mb-11" v-if="user">Текущий план на месяц:
          <span>{{ user.monthly_plan | VMask(currencyMask) }}</span></div>
      </div>
      <div class="user-page__subdivisions">
        <div class="user-page__headmaster mb-11" v-if="user.head && !user.is_main">
          <p>Руководитель</p>
          <v-menu
              top
              offset-y
              max-height="156"
              open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                  color="#FFFFFF"
                  size="53"
                  class="user-page__avatar"
                  @click="openUserPage(user.head.id)"
                  v-bind="attrs" v-on="on"
              >
                <img
                    :src="user.head.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
            </template>
            <v-list-item>{{ user.head.name }}</v-list-item>
          </v-menu>
        </div>
        <div class="user-page__subordinates" v-if="user.subordinates && user.subordinates.length">
          <p>Подчиненные</p>
          <div class="items">
            <v-menu
                top
                offset-y
                max-height="156"
                open-on-hover
                v-for="subuser in user.subordinates"
                :key="subuser.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    color="#FFFFFF"
                    size="53"
                    class="user-page__avatar"
                    @click="openUserPage(subuser.id)"
                    v-bind="attrs" v-on="on"
                >
                  <img
                      :src="subuser.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
              </template>
              <v-list-item>{{ subuser.name }}</v-list-item>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeUserModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Увольнение: {{ user.name }}
        </v-card-title>
        <v-card-text>Вы действительно хотите уволить сотрудника?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeUserModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeUser"
          >
            Уволить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {hasRight} from '@/utils/access/hasRight';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {SubdivisionAutocompleteRequestsMixin} from "@/mixins/autocomplete/SubdivisionAutocompleteRequestsMixin";
import {pluralize} from "../../tools/Helpers";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "UserPage",
  mixins: [OpenPagesMixin, SubdivisionAutocompleteRequestsMixin],
  data: () => ({
    subdivision: null,
    role: null,
    position: '',
    email: '',
    removeUserModal: false,
    plan: '',
    currencyMask,
  }),
  computed: {
    ...mapState("users", ["user", 'subdivisions']),
    ...mapState("permissions", ["roles",]),
    ...mapState("user", ["profile"]),
    ...mapState("common", ["loading"]),
    activityTime () {
      return this.returnTimeAnswer(this.user.today_activity_time);
    },
    userName () {
      if (!this.user.is_active) {
        return this.user.name + ' (Уволен)';
      }
      return this.user.name;
    },
    localSubdivisions() {
      if (this.subdivisions.length) {
        return this.subdivisions;
      } else if (this.user.subdivision) {
        return [this.user.subdivision];
      }
      return [];
    },
    localRoles() {
      if (this.roles.length) {
        return this.roles;
      } else if (this.user.roles) {
        return this.user.roles;
      }
      return [];
    },
    canChangeUserPlan() {
      return (this.user.head && this.profile && this.user.head.id === this.profile.id) || this.profile && this.profile.is_main;
    },
    planNum() {
      if (this.plan) {
        let priceArray = this.plan.split(" ");

        let sum = '';

        for (let i = 0; i < priceArray.length; i++) {
          sum += priceArray[i];
        }

        return parseFloat(sum);
      } else {
        return "";
      }
    },
    canViewAnalytics() {
      if (this.profile.id !== this.user.id) {
        return this.hasRight('analytics.users');
      }
      return this.hasRight('analytics.usersOwn')
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      editUser: 'users/editUser',
      getRoles: 'permissions/getRoles',
      getPermissions: 'permissions/getPermissions',
      fireUser: 'users/fireUser',
      restoreUser: 'users/restoreUser',
      getUserChat: 'chat/getUserChat',
    }),
    hasRight: hasRight,
    returnTimeAnswer(time) {
      let hours = Math.floor(time / 60);
      let minutes = Math.floor((time / 60 - hours) * 60);

      let minutesPluralize = ['минута', 'минуты', 'минут'];
      let hoursPluralize = ['час', 'часа', 'часов'];

      if (time > 60) {
        return pluralize(hours, hoursPluralize) + ' ' + pluralize(minutes, minutesPluralize);
      } else {
        return pluralize(time, minutesPluralize);
      }
    },
    getUserData() {
      let userId = this.$route.params.id;
      this.getUser(userId);
      this.getRoles();
    },
    editUserPage() {
      this.editUser({
        email: this.email,
        subdivision_id: this.subdivision,
        role_id: this.role,
        position: this.position || '',
        monthly_plan: this.planNum || this.user.monthly_plan,
        id: this.user.id,
      });
    },
    chat(id) {
      this.getUserChat(id).then((response) => {
        this.$router.push({path: `/chat/users/${response.id}`});
      });
    },
    openRemoveUserModal() {
      this.removeUserModal = true;
    },
    removeUser() {
      this.fireUser(this.user).then(() => {
        this.getUserData();
        this.removeUserModal = false;
      });
    },
    restoreUserBack() {
      this.restoreUser(this.user).then(() => {
        this.getUserData();
        this.removeUserModal = false;
      });
    },
  },
  watch: {
    user(val) {
      this.subdivision = val.subdivision.id;
      this.role = val.roles[0] ? val.roles[0].id : null;
      this.position = val.position;
      this.email = val.email;
    },
    '$route.params.id': {
      handler: function () {
        this.getUserData();
        this.plan = "";
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.user-page {
  background: #FFFFFF;
  border-radius: 32px;
  min-height: 751px;

  &__header {
    width: 100%;
    padding: 56px 94px;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0 24px 48px rgba(30, 28, 46, 0.08);
    border-radius: 32px;
  }

  &__close {
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.38);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 11px;
      /*position: absolute;*/
      top: 10px;
      left: 10px;
    }
  }

  &__name {
    display: flex;
    position: relative;

    .evi-text-field {
      width: 362px;
      max-width: 362px;
      margin-left: 24px;
      margin-top: 16px;
    }

    .users__status {
      position: absolute;
      top: 80px;
      left: 90px;

      img {
        width: 23px;
      }
    }
  }

  &__body {
    padding: 0 100px;
    margin-top: 54px;
    display: flex;
  }

  &__positions {
    max-width: 262px;
    margin-right: 95px;
    padding-top: 40px;
  }

  &__roles-select {
    display: flex;
    font-weight: 600 !important;
    font-size: 14px !important;

    span {
      display: block;
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #99AAB5;
      margin-right: 7px;
    }
  }

  .v-select__selection--comma {
    display: none !important;
  }

  &__subdivisions {
    border-left: 1px solid #EBEBEC;
    padding-left: 97px;
    padding-right: 25px;
    padding-top: 40px;

    p {
      font-weight: normal;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.38) !important;;
    }
  }

  &__headmaster {

    .user-page__avatar {
      cursor: pointer;
    }
  }

  &__subordinates {
    .items {
      max-width: 330px;

      .user-page__avatar {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

}
</style>
